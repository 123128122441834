<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div class="dx-viewport sx-scrollable" :id="`${gridId}-parent`">
    <AlertBox classes="mb-2" type="warning" :messages="responseError" />
    <div
      v-on:scroll.passive="sxScrollHandle"
      :id="`${gridId}-double-scroll-wrapper`"
      :class="{
        hidden: !isDoubleScrollNeeded || !gridShow || $isMobile(),
      }"
      class="sx-double-scroll-wrapper sx-double-scroll-table"
    >
      <div class="double-scroll"></div>
    </div>
    <input
      type="hidden"
      class="jsonData system-hidden-field"
      :value="JSON.stringify(jsonData)"
      :id="element.id"
      :name="element.id"
    />
    <DxDataGrid
      :ref="gridRefName"
      :id="gridId"
      :key-expr="primaryKey"
      :data-source="dataSource"
      :word-wrap-enabled="true"
      :allow-column-reordering="!$isMobile()"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      :column-hiding-enabled="!isNotDrawAsMobile"
      :allow-column-resizing="true"
      :column-resizing-mode="columnResizingMode"
      :column-auto-width="false"
      :focused-row-enabled="true"
      :auto-navigate-to-focused-row="true"
      :repaint-changes-only="rePaintChangesOnly"
      :hover-state-enabled="true"
      :remote-operations="false"
      noDataText=""
      :selection="selectionMode"
      @rowDblClick="rowDblClick"
      @cellDblClick="cellDblClick"
      @row-prepared="onRowPrepared"
      @cell-prepared="onCellPrepared"
      @initialized="initialized"
      @contentReady="contentReady"
      @option-changed="onOptionChanged"
      @init-new-row="initNewRow"
      @row-inserting="rowInserting"
      @edit-canceled="onEditCanceled"
      @editing-start="onEditingStart"
      @row-removed="rowRemoved"
      @row-inserted="rowInserted"
      @row-updating="rowUpdating"
      @row-updated="rowUpdated"
      @row-validating="onRowValidating"
      @key-down="onKeyDown"
    >
      <DxScrolling mode="standard" :use-native="false" show-scrollbar="never" />
      <DxKeyboardNavigation :enabled="true" />
      <DxSorting mode="multiple" />
      <DxColumnChooser :enabled="true" mode="select">
        <!-- <DxPosition
          my="right top"
          at="right bottom"
          of=".dx-datagrid-column-chooser-button"
        /> -->
        <DxColumnChooserSearch :enabled="true" />
      </DxColumnChooser>
      <DxSearchPanel
        v-if="
          table && table.viewFilter && tableViewFilter.isFilterSearchBoxActive
        "
        :visible="
          table && table.viewFilter && tableViewFilter.isFilterSearchBoxActive
        "
        :highlight-case-sensitive="true"
        :search-visible-columns-only="true"
      />

      <DxEditing
        :allow-updating="editSettings.allowEditing"
        :allow-deleting="true"
        :allow-adding="editSettings.allowAdding"
        :mode="editingMode"
      >
        <DxTexts confirm-delete-message="" />
      </DxEditing>

      <DxToolbar>
        <DxItem
          location="after"
          :visible="dxRowIsEditing"
          template="saveRowButtonTemplate"
        />
        <DxItem
          location="after"
          :visible="dxRowIsEditing"
          template="cancelRowButtonTemplate"
        />
        <DxItem
          location="before"
          template="lookupRelationInformationTemplate"
        />
        <DxItem
          v-if="table && table.viewFilter && table.viewFilter.isNewButtonActive"
          location="after"
          locateInMenu="auto"
          :disabled="dxRowIsEditing"
          template="addRowButtonTemplate"
        />
        <DxItem
          :visible="
            table &&
            table.viewFilter &&
            table.viewFilter.IsEditInlineOn &&
            !$isMobile()
          "
          location="after"
          locateInMenu="auto"
          template="shortcutInformationTemplate"
        />
        <DxItem
          :visible="!$isMobile()"
          location="after"
          locateInMenu="auto"
          template="fontSizeChangerTemplate"
        />
        <DxItem
          name="columnChooserButton"
          locateInMenu="auto"
          :disabled="dxRowIsEditing"
        />
        <DxItem
          name="searchPanel"
          locateInMenu="auto"
          :disabled="dxRowIsEditing"
        />
      </DxToolbar>

      <!-- Toolbar Templates -->
      <template #addRowButtonTemplate>
        <DxButtonToolbar icon="plus" class="dx-grid-add-row" @click="addRow" />
      </template>

      <template #saveRowButtonTemplate>
        <button
          type="button"
          class="btn btn-success dx-grid-record-save btn-icon"
          @click="saveRow"
        >
          <i class="fas fa-save"></i>
        </button>
      </template>

      <template #cancelRowButtonTemplate>
        <button
          type="button"
          class="btn btn-danger dx-grid-cancel-row btn-icon"
          @click="cancelRow"
        >
          <i class="fas fa-times-circle"></i>
        </button>
      </template>

      <template #lookupRelationInformationTemplate>
        <button
          type="button"
          class="btn btn-default btn-dx-grid-title text-capitalize btn-sm btn-no-custom-style fs-5 h-100"
          :style="{
            color: String.isNullOrWhiteSpace(element.fontColor)
              ? ''
              : element.fontColor + '!important',
          }"
          @click="tableToggleDisplay"
        >
          {{ table.name }}
        </button>
      </template>

      <template #shortcutInformationTemplate>
        <div
          class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-template-wrapper dx-item-content dx-toolbar-item-content"
          role="button"
          tabindex="0"
          data-bs-toggle="modal"
          :data-bs-target="`#shortcutInformationModal_${gridId}`"
        >
          <div class="dx-button-content">
            <i class="dx-icon dx-icon-info"></i>
          </div>
        </div>
      </template>

      <template #fontSizeChangerTemplate>
        <DxButtonToolbar icon="fontsize" @click="fontSizeChange" />
      </template>
      <!-- End Toolbar Templates -->

      <DxColumn
        v-for="(column, i) in columns"
        :key="i"
        :width="column.width > 0 ? `${column.width}px` : 'auto'"
        :data-field="column.formulaName"
        :data-type="column.type"
        :caption="column.text"
        :format="column.format"
        :alignment="column.textAlign"
        :sort-index="column.sortIndex"
        :sort-order="column.sortOrder"
        :allowEditing="column.allowEditing"
        :allowSorting="column.allowSorting"
        :edit-cell-template="column.editCellTemplate"
        :header-cell-template="column.headerCellTemplate"
        :formItem="{
          visible: ![this.serialNoKey, this.primaryKey].includes(
            column.formulaName
          ),
        }"
        v-model:visible="column.visible"
      >
        <template v-if="column.fieldModel && !$isMobile()">
          <DxRequiredRule
            v-if="
              (column.fieldModel.isRequired || column.required) &&
              column.fieldModel.fieldType != 'AutoId' &&
              !column.fieldModel.fieldType.includes('Formula') &&
              column.fieldModel.isActive
            "
          />
          <DxEmailRule v-if="column.fieldModel.fieldType == 'Email'" />
          <DxStringLengthRule
            :max="column.fieldModel.length"
            v-if="
              column.fieldModel.fieldType == 'Text' &&
              column.fieldModel.length > 0
            "
          />
        </template>
      </DxColumn>

      <DxSummary>
        <DxTotalItem
          v-for="(aggregate, i) in aggregates"
          :key="i"
          :column="aggregate.column"
          :summary-type="aggregate.type"
          :show-in-column="aggregate.showInColumn"
          :alignment="aggregate.alignment"
          :display-format="aggregate.displayFormat"
        />
      </DxSummary>

      <template
        v-for="(template, i) in headerCellTemplates"
        :key="template.id"
        #[template.id]="{ data }"
      >
        {{ data.column.caption }}
        <template v-if="!String.isNullOrWhiteSpace(template.field.helpText)">
          <i
            class="bi bi-question-circle-fill ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            :data-bs-original-title="`<div class='sx-grid-header-tooltip'>${template.field.helpText}</div>`"
          ></i>
        </template>
      </template>

      <!-- Inline Edit Cell Templates By Field Type Value -->
      <template
        v-for="(template, i) in editCellTemplates"
        :key="template.id"
        #[template.id]="{ data: { data } }"
      >
        <div v-bind="template.cell.htmlAttributes">
          <template
            v-if="
              this.$root.fieldTypes.text.includes(template.field.fieldType) ||
              this.$root.fieldTypes.html.includes(template.field.fieldType)
            "
          >
            <TextBox
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.checkbox.includes(template.field.fieldType)
            "
          >
            <CheckBox
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.number.includes(template.field.fieldType)
            "
          >
            <NumericTextBox
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.datetime.includes(template.field.fieldType)
            "
          >
            <DatePicker
              type="datetime"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="
                dateFormating(
                  'datetime',
                  editCellFieldValue(template.field, data)
                )
              "
              :template="template"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.date.includes(template.field.fieldType)
            "
          >
            <DatePicker
              type="date"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="
                dateFormating('date', editCellFieldValue(template.field, data))
              "
              :template="template"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.time.includes(template.field.fieldType)
            "
          >
            <DatePicker
              type="time"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="
                dateFormating('time', editCellFieldValue(template.field, data))
              "
              :template="template"
            />
          </template>
          <template
            v-else-if="
              template.field.fieldType == 'SelectList' && template.field.isRadio
            "
          >
            <RadioSelectList
              :items="
                radioSelectListValues.find(
                  (f) => f.fieldPublicId == template.field.publicId
                )['items']
              "
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :template="template"
              :rowData="data"
              :value="editCellFieldValue(template.field, data, 'text')"
              :valueId="editCellFieldValue(template.field, data, 'id')"
            />
          </template>
          <template
            v-if="
              template.field.fieldType == 'SelectList' &&
              !template.field.isRadio
            "
          >
            <SelectList
              :template="template"
              :rowData="data"
              :value="editCellFieldValue(template.field, data, 'text')"
              :valueId="editCellFieldValue(template.field, data, 'id')"
            />
          </template>
          <template
            v-else-if="
              template.field.fieldType != 'SelectList' &&
              this.$root.fieldTypes.select.includes(template.field.fieldType)
            "
          >
            <PagedSelectList
              :table="table"
              :rowData="data"
              :template="template"
              :value="editCellFieldValue(template.field, data, 'text')"
              :valueId="editCellFieldValue(template.field, data, 'id')"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.textarea.includes(template.field.fieldType)
            "
          >
            <DxTextArea
              @value-changed="
                editCellTemplateItemValueChangedForDxComponents(
                  $event,
                  template
                )
              "
              :input-attr="{ id: template.id, name: template.id }"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            >
            </DxTextArea>
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.file.includes(template.field.fieldType)
            "
          >
            <FileInputWithEdit
              v-if="data[primaryKey]"
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="data[template.field.formulaName]"
              :valueId="
                data[
                  template.field.formulaName +
                    template.parent.$fieldPublicIdFormulaExtension
                ]
              "
            />
            <FileInput
              v-else
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data, 'text')"
              :valueId="editCellFieldValue(template.field, data, 'id')"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.phone.includes(template.field.fieldType)
            "
          >
            <PhoneInput
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.spatial.includes(template.field.fieldType)
            "
          >
            <Spatial
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
        </div>
        <template v-if="template.hiddenFieldDependencies.length > 0">
          <template
            v-for="(hiddenTemplate, i) in template.hiddenFieldDependencies"
            :key="hiddenTemplate.id"
          >
            <div
              class="hidden column-hidden-dependency-field"
              v-bind="hiddenTemplate.cell.htmlAttributes"
            >
              <template v-if="hiddenTemplate.field.fieldType == 'Lookup'">
                <PagedSelectList
                  :table="table"
                  :rowData="data"
                  :template="hiddenTemplate"
                  :value="
                    editCellFieldValue(hiddenTemplate.field, data, 'text')
                  "
                  :valueId="
                    editCellFieldValue(hiddenTemplate.field, data, 'id')
                  "
                />
              </template>
            </div>
          </template>
        </template>
      </template>
      <!-- Inline Edit Cell Templates By Field Type Value -->

      <template
        v-if="
          customActionButtons.length > 0 || devExpressActionButtons.length > 0
        "
      >
        <DxColumn
          type="buttons"
          :caption="
            this.$t(
              'BaseModelFields.Commands',
              {},
              { locale: this.$store.state.activeLang }
            )
          "
        >
          <DxButton
            v-for="(button, i) in devExpressActionButtons"
            :key="button.uId"
            :name="button.name"
            :template="button.uId"
          >
          </DxButton>
          <DxButton
            @click="customActionButtonClick($event, button)"
            v-for="(button, i) in customActionButtons"
            :key="button.uId"
            :visible="isVisibleCustomActionButton"
            :template="button.uId"
          >
          </DxButton>
        </DxColumn>
      </template>

      <template
        v-for="(button, i) in devExpressActionButtons"
        :key="button.uId"
        #[button.uId]
      >
        <button
          type="button"
          class="btn btn-dx-system-button"
          :class="button.cssClass"
        >
          <template v-if="!String.isNullOrWhiteSpace(button.iconClass)">
            <i
              v-if="String.isNullOrWhiteSpace(button.iconColor)"
              :class="button.iconClass"
            ></i>
            <i
              v-else
              :style="`color:${button.iconColor} !important`"
              :class="button.iconClass"
            ></i>
            {{ button.label }}
          </template>
          <template v-else>
            {{ button.label }}
          </template>
        </button>
      </template>
      <template
        v-for="(button, i) in customActionButtons"
        :key="button.uId"
        #[button.uId]="{ data: { data } }"
      >
        <button
          v-if="button.type === 'button'"
          type="button"
          class="btn btn-sx-custom-button"
          :class="button.cssClass"
          v-bind="button.attributes"
        >
          <template v-if="!String.isNullOrWhiteSpace(button.iconClass)">
            <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              data-bs-placement="top"
              :data-bs-title="button.title"
            >
              <i
                v-if="String.isNullOrWhiteSpace(button.iconColor)"
                :class="button.iconClass"
              ></i>
              <i
                v-else
                :style="`color:${button.iconColor} !important`"
                :class="button.iconClass"
              ></i>
            </span>
            <template v-if="button.label">
              {{ button.label }}
            </template>
          </template>
          <template v-else>
            {{ button.title }}
          </template>
        </button>
        <a
          v-else-if="button.type === 'link'"
          :href="`${button.routeUrl}/${
            button.appendRowPublicId ? data[this.primaryKey] : ''
          }`"
          class="btn btn-sx-custom-link"
          :class="button.cssClass"
          v-bind="button.attributes"
        >
          <i :class="button.iconClass"></i>
          {{ button.label }}
        </a>
      </template>
    </DxDataGrid>
  </div>
  <InlineErrorModal
    :id="gridId"
    :inlineErrors="inlineErrors"
    :inlineWarnings="inlineWarnings"
    :informations="inlineInformations"
  />
  <CellEditModal
    ref="cellEditModal"
    :id="gridId"
    :selectedRowIndex="selectedRowIndex"
    :selectedCellIndex="selectedCellIndex"
    :selectedRecordCellField="selectedRecordCellField"
    :selectedRowData="selectedRowData"
    :fieldValues="selectedRowRequiredFieldAndValues"
    :isNewRecord="true"
    @updatedCell="updatedCell"
  />
  <ShortcutInformationModal
    v-if="table"
    :id="gridId"
    :table="table"
    :viewFilter="table.viewFilter"
  />
</template>
<script>
import PagedSelectList from "@/components/devexpress/grid/editing/inline/PagedSelectList.vue";
import SelectList from "@/components/devexpress/grid/editing/inline/SelectList.vue";
import RadioSelectList from "@/components/devexpress/grid/editing/inline/RadioSelectList.vue";
import FileInput from "@/components/devexpress/grid/editing/inline/FileInput.vue";
import FileInputWithEdit from "@/components/devexpress/grid/editing/inline/FileInputWithEdit.vue";
import PhoneInput from "@/components/devexpress/grid/editing/inline/PhoneInput.vue";
import NumericTextBox from "@/components/devexpress/grid/editing/inline/NumericTextBox.vue";
import TextBox from "@/components/devexpress/grid/editing/inline/TextBox.vue";
import CheckBox from "@/components/devexpress/grid/editing/inline/CheckBox.vue";
import DatePicker from "@/components/devexpress/grid/editing/inline/DatePickerByType.vue";
import Spatial from "@/components/devexpress/grid/editing/inline/Spatial.vue";

import InlineErrorModal from "@/components/custom/set-pages/InlineErrorModal.vue";
import CellEditModal from "@/components/devexpress/grid/editing/modal/CellEditModal.vue";
import ShortcutInformationModal from "@/components/devexpress/grid/information/ShortcutModal";

//devexpress plugins
import DxButtonToolbar from "devextreme-vue/button";
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxColumnChooser,
  // DxPosition,
  DxColumnChooserSearch,
  DxSearchPanel,
  DxButton,
  DxKeyboardNavigation,
  DxTexts,
  DxSorting,
  DxToolbar,
  DxItem,
  DxScrolling,
  DxRequiredRule,
  DxEmailRule,
  DxStringLengthRule,
  DxSummary,
  DxTotalItem,
} from "devextreme-vue/data-grid";

// import DxTextBox from "devextreme-vue/text-box";
// import DxDateBox from "devextreme-vue/date-box";
import DxTextArea from "devextreme-vue/text-area";
// import DxCheckBox from "devextreme-vue/check-box";

import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { showModal, removeTooltips } from "@/core/helpers/dom";

export default {
  name: "DevExpressLocalGrid",
  components: {
    InlineErrorModal,
    CellEditModal,
    ShortcutInformationModal,

    //Devexpress Data Grid
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxSorting,
    DxButton,
    DxButtonToolbar,
    DxScrolling,
    DxColumnChooser,
    // DxPosition,
    DxColumnChooserSearch,
    DxKeyboardNavigation,
    DxSearchPanel,
    DxTexts,
    DxToolbar,
    DxItem,
    DxRequiredRule,
    DxEmailRule,
    DxStringLengthRule,
    DxSummary,
    DxTotalItem,

    //Devexpress Components
    // DxTextBox,
    // DxDateBox,
    DxTextArea,
    // DxCheckBox,

    //Custom Devexpress Edit Cell Component
    PagedSelectList,
    SelectList,
    RadioSelectList,
    FileInput,
    FileInputWithEdit,
    PhoneInput,
    NumericTextBox,
    TextBox,
    CheckBox,
    DatePicker,
    Spatial,
  },
  props: {
    gridId: {
      type: String,
      default: String.newGuid(),
    },
    isLookupRelationTable: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object,
      default() {
        return null;
      },
    },
    table: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ["onRequestUnSuccessful", "onRequestFinally"],
  data() {
    return {
      numberFormatInfo: this.$root.getNumberFormat(),
      dateFormatInfo: this.$root.getDateTimeFormat("short"),
      dateTimeFormatInfo: this.$root.getDateTimeFormat(),

      primaryKey: "PUBLICID",
      serialNoKey: "SX_DISPLAY_ORDER",
      dxSystemPrimaryKey: "__KEY__",
      dxRowIsEditing: false,
      isDxRowInlineEditing: false,
      rePaintChangesOnly: false,
      gridRefName: "grid",
      dataSource: [],
      tableViewFilter: this.table.viewFilter,
      isNotDrawAsMobile: false,

      selectionMode: { mode: "single" }, //or multiple
      columnResizingMode: this.$isMobile() ? "nextColumn" : "widget", //or nextColumn
      editingMode: this.$isMobile() ? "form" : "row", //or batch, cell, popup
      pageSizes: [10, 25, 50, 100, 200, 500],
      pageSize: 50,

      isDoubleScrollNeeded: false,
      currentFontSize: 16,
      minFontSize: 14,
      maxFontSize: 20,
      maxSortOrder: 3,

      jsonData: {
        lookupRelationPublicId: this.element.id,
        customObjectKey: this.table.lookupObjectUrlName,
        lookupObjectFormulaName: this.table.fieldFormulaName,
        values: [],
      },

      gridShow: false,
      isTableLoaded: false,
      spinShow: false,
      isLoaded: false,
      isTableShow: false,
      tableNoPermitted: false,
      onCellDoubleClicked: false,
      editSettings: {
        allowAdding: true,
        allowEditing: true,
      },

      //buttons
      filterButtons: [],
      customActionButtons: [],
      devExpressActionButtons: [],
      clickedCurrentButton: [],

      columns: [],
      hiddenDependenyFieldItems: [],
      aggregates: [],
      editCellTemplates: [],
      headerCellTemplates: [],
      pageData: null,
      pagedItems: [],
      pageAllFields: [],

      editingField: null,

      newRowFieldDefaultValues: null,

      shortDateTimeFormat: this.$root.getDateTimeFormat("short"),
      longDateTimeFormat: this.$root.getDateTimeFormat(),

      //inline errors
      inlineErrors: [],
      inlineWarnings: [],
      inlineInformations: [],

      //request errors
      responseError: [],

      //cell edit
      selectedRow: null,
      selectedRowData: null,
      selectedRecordCellField: null,
      selectedRowRequiredFieldAndValues: [],
      selectedRowIndex: 0,
      selectedCellIndex: 0,

      radioSelectListValues: [],
    };
  },
  computed: {
    grid() {
      return this.$refs[this.gridRefName]
        ? this.$refs[this.gridRefName].instance
        : null;
    },
  },
  created() {
    this.getTable();
  },
  mounted() {
    //inline download
    var self = this;
    $("body")
      .off("click", ".download-file")
      .on("click", ".download-file", function () {
        self.$root.downloadFile({
          id: $(this).attr("id"),
          name: $(this).attr("name"),
        });
      });

    $("body")
      .off("click", ".view-map-with-swal")
      .on("click", ".view-map-with-swal", function () {
        var el = $(this),
          title = el.data("title"),
          latLng = el.data("latlng");

        if (
          String.isNullOrWhiteSpace(title) ||
          String.isNullOrWhiteSpace(latLng)
        )
          return;

        var lat = latLng.split(",")[0],
          lng = latLng.split(",")[1];

        self.$root.viewMapWithSwal(title, lat, lng);
      });
  },
  watch: {},
  methods: {
    onKeyDown(e) {
      var event = e.event,
        isCtrlK = event.ctrlKey === true && event.keyCode === 75,
        isCtrlY = event.ctrlKey === true && event.keyCode === 89;
      if (isCtrlK || isCtrlY) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        if (isCtrlK) {
          this.saveRow();
        } else if (isCtrlY && this.table.viewFilter.isNewButtonActive) {
          this.addRow();
        }
      }
    },
    onRowValidating(e) {
      // if (!this.$isMobile()) return;

      var obj = e.newData,
        messages = [],
        self = this,
        grid = $(`#${this.gridId}`);

      if (String.isNullOrWhiteSpace(obj) || !grid) return;

      Object.keys(obj).forEach(function (key) {
        var field = self.pageAllFields.find((f) => f.formulaName == key),
          filterField = self.columns.find((f) => f.formulaName == key),
          value = String.isNullOrWhiteSpace(obj[key])
            ? null
            : obj[key].toString();

        if (!field) return;

        if (!filterField) {
          filterField = self.hiddenDependenyFieldItems.find(
            (f) => f.field.formulaName == key
          ).filterField;
        }

        var columnIndex = self.grid.getVisibleColumnIndex(field.formulaName),
          messageIndex = messages.length + 1,
          fieldNameHtmlFormat = `<b>${field.name}</b>`,
          emailNotValidMessage = self
            .$t(
              "FieldWarnings.EmailNotValidFormat",
              {},
              { locale: self.$store.state.activeLang }
            )
            .replaceAll("FIELD_NAME", fieldNameHtmlFormat),
          phoneNotValidMessage = self
            .$t(
              "FieldWarnings.PhoneNotValidFormat",
              {},
              { locale: self.$store.state.activeLang }
            )
            .replaceAll("FIELD_NAME", fieldNameHtmlFormat),
          fieldLengthNotValidMessage = self
            .$t(
              "FieldWarnings.FieldLengthNotValidFormat",
              {},
              { locale: self.$store.state.activeLang }
            )
            .replaceAll("FIELD_NAME", fieldNameHtmlFormat),
          fieldIsRequiredMessage = self
            .$t(
              "FieldWarnings.FieldIsRequiredFormat",
              {},
              { locale: self.$store.state.activeLang }
            )
            .replaceAll("FIELD_NAME", fieldNameHtmlFormat),
          columnOrderNo = `<span class='badge badge-validation bg-info fs-6'>
            ${columnIndex}${self.$t(
            "BaseModelFields.NdPlace",
            {},
            { locale: self.$store.state.activeLang }
          )}</span>`;

        if (
          (filterField.required || field.isRequired) &&
          field.fieldType != "AutoId" &&
          !field.fieldType.includes("Formula") &&
          String.isNullOrWhiteSpace(value) &&
          field.isActive
        ) {
          messages.push(
            `${messageIndex}. ${fieldIsRequiredMessage}. ${columnOrderNo}`
          );
        }

        if (!String.isNullOrWhiteSpace(value) && field.fieldType === "Phone") {
          var intlInstance = self.$root.getPhoneInputInstance(
            self.getCellTemplateId(2, field)
          );
          if (intlInstance) {
            if (!intlInstance.isValidNumber()) {
              messages.push(
                `${messageIndex}. ${phoneNotValidMessage}. ${columnOrderNo}`
              );
            }
          }
        }

        if (
          field.fieldType == "Email" &&
          !String.isNullOrWhiteSpace(value) &&
          !String.isEmail(value)
        ) {
          messages.push(
            `${messageIndex}. ${emailNotValidMessage}. ${columnOrderNo}`
          );
        }

        if (
          field.fieldType == "Text" &&
          field.length > 0 &&
          !String.isNullOrWhiteSpace(value) &&
          value.length > field.length
        ) {
          messages.push(
            `${messageIndex}. ${fieldLengthNotValidMessage}. ${columnOrderNo}`
          );
        }
      });

      e.isValid = messages.length === 0;
      e.errorText = messages.length > 0 ? " " : null;

      setTimeout(function () {
        const errorRow = grid.find(".dx-error-message");
        if (!e.isValid) {
          errorRow.html(messages.join("<br/>"));
        } else {
          errorRow.addClass("hidden");
        }
      });
    },
    updatedCell(newValue, selectedRowData, selectedRecordCellField) {
      var record = this.dataSource.find(
        (f) => f[this.primaryKey] == selectedRowData[this.primaryKey]
      );
      if (String.isNullOrWhiteSpace(record)) return;

      record[newValue.key] = newValue.text;
      if (
        record[newValue.key + this.$fieldPublicIdFormulaExtension] ||
        this.$root.fieldTypes.select.includes(
          selectedRecordCellField.fieldType
        ) ||
        this.$root.fieldTypes.file.includes(selectedRecordCellField.fieldType)
      ) {
        record[newValue.key + this.$fieldPublicIdFormulaExtension] =
          newValue.value;
      }

      if (this.isLookupRelationTable) {
        this.$root.rollupTrigger(
          this.element.id,
          this.$parent.$parent.pageData.fields.find((f) => f.isMainObject)
            .fieldModels
        );
      }
    },
    rowRemoved() {
      this.grid.refresh();
      this.$root.rollupTrigger(
        this.element.id,
        this.$parent.$parent.pageData.fields.find((f) => f.isMainObject)
          .fieldModels
      );
    },
    rowInserted() {
      var self = this;
      (function loop() {
        setTimeout(function () {
          if (
            $(`[data-id="${self.newRowFieldDefaultValues.recordPublicId}"]`)
              .length > 0
          ) {
            self.$root.rollupTrigger(
              self.element.id,
              self.$parent.$parent.pageData.fields.find((f) => f.isMainObject)
                .fieldModels
            );
            self.newRowFieldDefaultValues = null;
            return;
          }
          loop();
        }, 50);
      })();
    },
    rowUpdating(e) {
      if ($.isEmptyObject(e.newData)) return;

      var itemObj = e.oldData,
        newData = e.newData;
      Object.keys(newData).forEach(function (key) {
        itemObj[key] = newData[key];
      });

      itemObj = this.createGridItemObj(itemObj);
      var row = this.modelCreate(itemObj, false),
        parentModel = this.$root.modelCreate("#newForm");

      parentModel.tableValues = [];

      parentModel.tableValues.push(row);

      this.tableItemValidationAfterActionComplete(itemObj, parentModel, false);
      e.cancel = true;
    },
    rowUpdated(recordPublicId) {
      var self = this;
      (function loop() {
        setTimeout(function () {
          if (!$(`[data-id="${recordPublicId}"]`).hasClass("dx-edit-row")) {
            self.$root.rollupTrigger(
              self.element.id,
              self.$parent.$parent.pageData.fields.find((f) => f.isMainObject)
                .fieldModels
            );
            return;
          }
          loop();
        }, 50);
      })();
    },
    tableItemValidationAfterActionComplete(gridItemObj, model, isNewRecord) {
      this.inlineErrors = [];
      this.inlineWarnings = [];
      this.inlineInformations = [];

      this.grid.beginCustomLoading();

      model.values = model.values
        .filter(function (f) {
          return !String.isNullOrWhiteSpace(f.value);
        })
        .map(function (m) {
          return { key: m.key, value: m.value };
        });
      this.$appAxios
        .post("/rws-SetController-ValidationToPostRecord", model)
        .then((response) => {
          this.grid.endCustomLoading();

          var result = response.data;

          if (!String.isNullOrWhiteSpace(result.msg)) {
            this.inlineErrors.push(result.msg);
          }

          if (!String.isNullOrWhiteSpace(result.message)) {
            this.inlineErrors.push(result.message);
          }

          if (!String.isNullOrWhiteSpace(result.validations)) {
            result.validations.forEach((validation) => {
              var errorMessage = validation.message;
              if (!String.isNullOrWhiteSpace(validation.fieldName)) {
                errorMessage = `${validation.fieldName}: ${validation.message}`;
              }
              if (
                validation.validationType &&
                validation.validationType.value == 2
              ) {
                this.inlineWarnings.push({
                  message: errorMessage,
                  model: model,
                  self: this,
                  component: "Grid",
                });
              } else if (
                validation.validationType &&
                validation.validationType.value == 3
              ) {
                this.inlineInformations.push(validation);
              } else {
                this.inlineErrors.push(errorMessage);
              }
            });
          }

          if (result.isOk) {
            this.inlineErrors = [];
            this.inlineWarnings = [];
          }

          if (
            this.inlineErrors.length > 0 ||
            this.inlineWarnings.length > 0 ||
            this.inlineInformations.length > 0
          ) {
            showModal(
              document.getElementById(`modalInlineError_${this.gridId}`)
            );
          }

          if (result.isOk) {
            this.setRadioSelectListValuesDefaultValues();
            this.dxRowIsEditing = false;
            this.isDxRowInlineEditing = false;
            if (isNewRecord) {
              this.dataSource.splice(0, 0, gridItemObj);
              this.rowInserted();
            } else {
              this.dataSource.splice(
                this.dataSource.findIndex(
                  (f) => f[this.primaryKey] == gridItemObj[this.primaryKey]
                ),
                1,
                gridItemObj
              );
              this.rowUpdated(model.recordPublicId);
            }

            this.grid.cancelEditData();
            this.grid.refresh();

            this.jsonData.values = this.dataSource;
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    rowInserting(e) {
      var itemObj = this.createGridItemObj(e.data),
        row = this.modelCreate(itemObj),
        parentModel = this.$root.modelCreate("#newForm");

      parentModel.tableValues = [];

      parentModel.tableValues.push(row);

      this.tableItemValidationAfterActionComplete(itemObj, parentModel, true);

      e.cancel = true;

      this.$root.createEvent("SXGRID_ON_ROW_INSERTING", {
        title: "Grid On Row Inserting",
        message: "Event triggered when grid row inserting",
        grid: {
          id: this.gridId,
          data: this.pageData,
          pagedItems: this.pagedItems,
          rowData: e.data,
        },
      });
    },
    setRadioSelectListValuesDefaultValues() {
      //default value
      this.radioSelectListValues = this.radioSelectListValues.map(function (f) {
        return {
          fieldPublicId: f.fieldPublicId,
          items: f.items,
        };
      });
    },
    saveRow() {
      var dxCalculateFieldLoading = $(this.grid.$element()).find(
        ".dx-save-loading"
      );
      if (dxCalculateFieldLoading.length > 0) return;

      this.grid.saveEditData();
    },
    cancelRow() {
      this.grid.cancelEditData();
    },
    onEditCanceled(e) {
      this.dxRowIsEditing = false;
      this.isDxRowInlineEditing = false;
      this.onCellDoubleClicked = false;
      this.setRadioSelectListValuesDefaultValues();
    },
    onEditingStart(e) {
      this.dxRowIsEditing = true;
      this.isDxRowInlineEditing = true;
      if (!this.onCellDoubleClicked) {
        this.sxScrollSetLeftValue(0);
      }
    },
    initNewRow(e) {
      this.dxRowIsEditing = true;
      this.isDxRowInlineEditing = false;
      this.fieldDefaultValueItemsUnLocked();
      this.sxScrollSetLeftValue(0);
      this.setRadioSelectListValuesDefaultValues();
    },
    editCellFieldIsDisabled(field, rowData) {
      if (!this.dxRowIsEditing) return;

      var filterField = this.columns.find(
        (f) => f.formulaName == field.formulaName
      );
      if (!filterField) return;

      return (
        field.disabled ||
        filterField.disabled ||
        !field.isActive ||
        field.fieldType.includes("AutoId") ||
        field.fieldType.includes("Rollup") ||
        field.fieldType.includes("Formula") ||
        (rowData[this.primaryKey] && !field.isEditable)
      );
    },
    getDataSourceItem(publicId) {
      var items = this.grid.getDataSource().items();
      return items && items.find((f) => f[this.primaryKey] == publicId);
    },
    editCellTemplateItemValueChangedForDxComponents(
      e,
      template,
      isCalculatedField = false
    ) {
      this.editingField = template.field;
      var element = $(e.element),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      if (isCalculatedField) {
        this.$root.calculateTriggerFieldForGrid(this, template);
      }

      this.grid.cellValue(rowIndex, columnIndex, e.value);
    },
    editCellTemplateValueChangedForDatePickerComponents(
      template,
      isCalculatedField = false,
      datePickerType = "datetime",
      value = null
    ) {
      this.editingField = template.field;
      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      if (
        !element ||
        !this.$root.validateDatePickerType(template.field.fieldType)
      )
        return;

      if (isCalculatedField) {
        this.$root.calculateTriggerFieldForGrid(this, template);
      }

      if (!String.isNullOrWhiteSpace(value)) {
        if (datePickerType == "datetime") {
          value = this.$moment(value, "yyyy-MM-DDTHH:mm:ss").format(
            this.dateTimeFormatInfo.calendar
          );
        } else if (datePickerType == "date") {
          value = this.$moment(value, "yyyy-MM-DD").format(
            this.dateFormatInfo.calendar
          );
        } else if (datePickerType == "time") {
          value = this.$moment(value, "yyyy-MM-DDTHH:mm").format(
            this.dateFormatInfo.time
          );
        }
      }

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForPhoneInput(template, value) {
      this.editingField = template.field;

      var fullNumberInput = $(`[name="${template.id}_full_phone_number"]`);
      if (fullNumberInput) {
        value = fullNumberInput.val();
      }

      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForNumberFieldTypes(template, value) {
      this.editingField = template.field;
      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForSelect2Components(
      field,
      elementId,
      text,
      value,
      template
    ) {
      this.editingField = template.field;
      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      var row = element.closest("tr"),
        childs = this.$root.getChildFieldPublicIds(field.publicId);
      childs.forEach((childFieldPublicId) => {
        var gridItem = row.find(`[data-publicid='${childFieldPublicId}']`);
        if (gridItem) {
          var childColumnIndex = this.grid.getVisibleColumnIndex(
            gridItem.data("formulaname")
          );
          this.grid.cellValue(rowIndex, childColumnIndex, null);
        }
      });

      this.grid.cellValue(rowIndex, columnIndex, value);

      if (!String.isNullOrWhiteSpace(field.controllingPublicId)) {
        this.$root.select2PagedSelectlistDependencyChildTriggerForGrid(
          $(`#${elementId}`).closest("tr"),
          field,
          template
        );
      }

      if (
        !String.isNullOrWhiteSpace(value) &&
        !String.isNullOrWhiteSpace(text)
      ) {
        this.$root.select2SetValue(
          $(`#${elementId}`),
          field,
          value,
          text,
          true
        );
      }
    },
    editCellTemplateValueChangedForRadioSelectListFieldTypes(template, value) {
      this.editingField = template.field;
      var element = $(`[name="${template.id}"]`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.radioSelectListValues.find(
        (f) => f.fieldPublicId == template.field.publicId
      ).editCellFieldValueChanges = true;

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForSpatial(template, value) {
      this.editingField = template.field;
      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForFileInput(template, value) {
      this.editingField = template.field;
      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    tableToggleDisplay() {
      this.$parent.isTableShow = false;
    },
    editCellTemplateValueChangedForTextBoxFieldTypes(template, value) {
      this.editingField = template.field;
      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      if (template.field.isEncrypted && !String.isNullOrWhiteSpace(value)) {
        value = this.$root.encrypt(value);
      }

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForCheckBoxFieldTypes(template, value) {
      this.editingField = template.field;
      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    fieldDefaultValueItemsUnLocked() {
      if (
        this.newRowFieldDefaultValues &&
        this.newRowFieldDefaultValues.items
      ) {
        this.newRowFieldDefaultValues.items.forEach((fieldDefaultValue) => {
          fieldDefaultValue.locked = false;
        });
      }
    },
    editCellFieldValue(field, rowData = [], returnType = "text") {
      if (!this.dxRowIsEditing) return;

      var value = rowData[field.formulaName],
        recordPublicId = rowData[this.primaryKey],
        isNewRecord = String.isNullOrWhiteSpace(recordPublicId),
        isEditRecord = !isNewRecord;

      if (
        isNewRecord &&
        returnType == "text" &&
        this.$root.fieldTypes.select.includes(field.fieldType)
      ) {
        value = rowData[field.formulaName + "_TEXT"];
      }

      if (isNewRecord && String.isNullOrWhiteSpace(value)) {
        var fieldDefaultValues = this.newRowFieldDefaultValues.items,
          fieldDefaultValue = !String.isNullOrWhiteSpace(fieldDefaultValues)
            ? fieldDefaultValues.find((f) => f.key == field.formulaName)
            : null,
          defaultValueIsNull = String.isNullOrWhiteSpace(fieldDefaultValue);

        if (defaultValueIsNull) {
          value = null;
        } else if (fieldDefaultValue.value.includes("error")) {
          value = null;

          var valueIsLocked =
            "locked" in fieldDefaultValue && fieldDefaultValue.locked;
          if (!valueIsLocked) {
            fieldDefaultValue.locked = true;
          }
        } else {
          valueIsLocked =
            "locked" in fieldDefaultValue && fieldDefaultValue.locked;
          if (!valueIsLocked) {
            if (
              (this.$root.fieldTypes.select.includes(field.fieldType) &&
                returnType == "id") ||
              (!this.$root.fieldTypes.select.includes(field.fieldType) &&
                returnType == "text")
            ) {
              fieldDefaultValue.locked = true;
            }

            value = fieldDefaultValue.value;
          }

          if (
            !String.isNullOrWhiteSpace(value) &&
            this.$root.fieldTypes.select.includes(field.fieldType)
          ) {
            // var fieldValueIsMultiple =
            //     value.split(this.$systemSeparator).length > 2,
            var splittedValue = value.split(this.$selectSeparator);
            // if (fieldValueIsMultiple) {
            //   value = splittedValue[returnType == "id" ? 0 : 1].split(
            //     this.$systemSeparator
            //   )[0];
            // } else {
            value = splittedValue[returnType == "id" ? 0 : 1];
            // }
          }
        }

        //emiting default value to column
        if (
          returnType == "text" &&
          this.$root.fieldTypes.select.includes(field.fieldType)
        ) {
          rowData[field.formulaName + "_TEXT"] = value;
        } else {
          rowData[field.formulaName] = value;
        }
      } else if (isEditRecord && !String.isNullOrWhiteSpace(value)) {
        if (this.$root.fieldTypes.select.includes(field.fieldType)) {
          if (returnType == "id") {
            value =
              rowData[
                `${field.formulaName}${this.$fieldPublicIdFormulaExtension}`
              ];
          }
        }
      }

      if (this.$root.fieldTypes.checkbox.includes(field.fieldType)) {
        if (!String.isNullOrWhiteSpace(value)) {
          value = Boolean.toBool(value.toString());
        } else {
          value = false;
        }
      }

      return value;
    },
    dateFormating(type, dataItem) {
      if (
        String.isNullOrWhiteSpace(dataItem) ||
        String.isNullOrWhiteSpace(type) ||
        dataItem.includes("-")
      )
        return String.isNullOrWhiteSpace(dataItem) ? null : dataItem;

      if (type == "datetime") {
        return this.$moment(dataItem, this.dateTimeFormatInfo.calendar).format(
          "yyyy-MM-DDTHH:mm:ss"
        );
      } else if (type == "date") {
        return this.$moment(dataItem, this.dateFormatInfo.calendar).format(
          "yyyy-MM-DD"
        );
      } else if (type == "time") {
        var valueFormat = this.$root.getTimeFormatByValue(
          dataItem,
          this.dateTimeFormatInfo
        );

        return this.$moment(dataItem, valueFormat).format(
          "yyyy-MM-DDTHH:mm:ss"
        );
      }

      return dataItem;
    },
    addRow(e) {
      this.rePaintChangesOnly = false;
      if (!this.newRowFieldDefaultValues) {
        this.getTableFieldDefaultValues();
      } else {
        setTimeout(() => {
          this.rePaintChangesOnly = true;
          this.grid.addRow();
        }, 1);
      }
    },
    getTableFieldDefaultValues() {
      // debugger;
      this.grid.beginCustomLoading(
        this.$t(
          "BaseModelFields.DefaultValuesRequestMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      );

      var parentModel = this.$root.modelCreate("#newForm"),
        parentModelOnlyValues = parentModel.values,
        dataModel = {
          publicId: String.newGuid(),
          fieldsValues: [],
        };
      if (parentModelOnlyValues && parentModelOnlyValues.length > 0) {
        parentModelOnlyValues = parentModelOnlyValues
          .filter(
            (f) =>
              !f.fieldType.includes("Formula") &&
              !String.isNullOrWhiteSpace(f.value)
          )
          .map(function (m) {
            return {
              key: m.key,
              value: m.value,
            };
          });

        dataModel.fieldsValues = [
          ...dataModel.fieldsValues,
          ...parentModelOnlyValues,
        ];
      }

      var model = {
        viewFilterPublicId: this.table.viewFilterPublicId,
        isLookupRelationRequest: false,
        relationFieldFormulaName: this.table.fieldFormulaName,
        data: JSON.stringify(dataModel),
      };

      this.$appAxios
        .post("rws-SetController-NewPageForViewFilter", model)
        .then((response) => {
          this.grid.endCustomLoading();

          var result = response.data;
          if (result.isOk) {
            this.newRowFieldDefaultValues = {
              recordPublicId: result.recordPublicId,
              items: result.values,
            };
            this.rePaintChangesOnly = true;
            this.grid.addRow();
          } else {
            createToast(result.msg, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    fontSizeChange(e) {
      var fontSize = ++this.currentFontSize;
      var realFontSize = this.$root.gridFontSizeChanger(this, fontSize);
      Object.setCookie("SxGridFontSize", realFontSize);
    },
    initialized(e) {
      //ui before
      this.$root.createEvent("SXGRID_INITIALIZED", {
        title: "Grid Initialized",
        message: "Event triggered when grid initialized",
        grid: {
          id: this.gridId,
          data: this.table,
          pagedItems: this.dataSource,
        },
      });
    },
    setTableGradientAndFontColor() {
      if (
        !String.isNullOrWhiteSpace(this.element.linearGradient) ||
        !String.isNullOrWhiteSpace(this.element.tableFontColor) ||
        !String.isNullOrWhiteSpace(this.element.fontColor)
      ) {
        var grid = $(`#${this.gridId}`);
        if (!String.isNullOrWhiteSpace(this.element.linearGradient)) {
          grid
            .find(
              ".dx-toolbar, .dx-datagrid-header-panel, .dx-pager, .dx-scrollable-scroll-content"
            )
            .css("background", this.element.linearGradient);
        }

        if (!String.isNullOrWhiteSpace(this.element.fontColor)) {
          grid.find(".btn-dx-grid-title").css("color", this.element.fontColor);
        }

        if (!String.isNullOrWhiteSpace(this.element.tableFontColor)) {
          grid
            .find(
              `td[role='columnheader'],
              .dx-datagrid-nodata,
              .btn-sx-cell-editing-enabled-info i`
            )
            .css("color", this.element.tableFontColor);
        }
      }
    },
    contentReady(e) {
      this.setTableGradientAndFontColor();

      var fontSizeCookieValue = Object.readCookie("SxGridFontSize");
      if (!String.isNullOrWhiteSpace(fontSizeCookieValue)) {
        this.currentFontSize = fontSizeCookieValue;
        this.$root.gridFontSizeChanger(this, fontSizeCookieValue);
      }

      this.gridShow = true;
      this.configurationSxScroll();

      //creating content ready event
      this.$root.createEvent("SXGRID_CONTENT_READY", {
        title: "Grid Content Ready",
        message: "Event triggered when grid content ready",
        grid: {
          id: this.gridId,
          data: this.table,
          pagedItems: this.dataSource,
        },
      });
    },
    configurationSxScroll() {
      var contentScroll = $(`#${this.gridId} .dx-datagrid-content:first`)[0],
        sxScroll = $(`#${this.gridId}-double-scroll-wrapper .double-scroll`);

      if (!contentScroll || !sxScroll) return;

      this.isDoubleScrollNeeded =
        contentScroll.scrollWidth > contentScroll.clientWidth;

      sxScroll.width(contentScroll.scrollWidth);
    },
    sxScrollHandle() {
      var dxScroll = this.grid.getScrollable(),
        customScrollLeftMargin = $(
          `#${this.gridId}-double-scroll-wrapper`
        ).scrollLeft();

      dxScroll.scrollTo({ left: customScrollLeftMargin });
    },
    customActionButtonClick($event, button) {
      var rowData = $event.row.data,
        recordPublicId = rowData[this.primaryKey],
        customObjectKey = this.isLookupRelationTable
          ? this.table.lookupObjectUrlName
          : this.$route.params.key;

      removeTooltips();
      switch (button.name) {
        case "SetXRMCustomButton":
          break;
        case "DeleteRecord":
          this.grid.deleteRow($event.row.dataIndex);
          break;
        case "EditRecord":
          this.rePaintChangesOnly = false;
          setTimeout(() => {
            this.rePaintChangesOnly = true;
            this.grid.editRow($event.row.dataIndex);
          }, 1);
          break;
        default:
          return;
      }
    },
    onRowPrepared(e) {
      if (
        e.rowType == "data" ||
        e.rowType == "detailAdaptive" ||
        e.rowType == "detail"
      ) {
        if (this.primaryKey in e.data) {
          e.rowElement.setAttribute("data-id", e.data[this.primaryKey]);
        }

        e.rowElement.classList.add("sx-record-item");
      }
    },
    getColumnAttributes(field) {
      return this.tableViewFilter.isCellEditingEnabled
        ? {
            "data-celledit-publicid": field.publicId,
            "data-celledit-class": "grid-item",
            "data-celledit-fieldtype": field.fieldType,
            "data-celledit-inputtype": field.inputHtmlType,
            "data-celledit-maxlength": field.length,
            "data-celledit-islivelookup": field.isDataReadFromMainTable,
            "data-celledit-iscalculatedonlyoninsert":
              field.isCalculatedOnlyOnInsert,
            "data-celledit-decimalplaces": field.decimalPlaces,
            "data-celledit-inputmaskpattern": field.inputMaskPattern,
            "data-celledit-controllingpublicid": field.controllingPublicId,
            "data-celledit-lookupobjectid": field.lookupObjectPublicId,
            "data-celledit-lookupobjectname": field.lookupObjectName,
            "data-celledit-lookupobjecturl": field.lookupObjectKey,
            "data-celledit-lookupfieldids": field.lookupFieldPublicIds,
            "data-selecteditempublicids": field.defaultValuePublicIds,
            "data-celledit-organizationalunitname":
              field.organizationalUnitName,
            "data-celledit-organizationalunitfiltertype":
              field.organizationalUnitFilterType,
            "data-celledit-organizationalunitgrouppublicids":
              field.organizationalUnitGroupPublicIds,
            "data-celledit-organizationalunitdepth":
              field.organizationalUnitDept,
            "data-celledit-organizationalunitincludeitself":
              field.organizationalUnitIncludeItself,
            "data-celledit-isencrypted": field.isEncrypted,
            "data-celledit-ismultiplevalue": field.isMultipleValue,
            "data-celledit-isradio": field.isRadio,
            "data-celledit-isrequired": field.isRequired,
            "data-celledit-isunique": field.isUnique,
            "data-celledit-isactive": field.isActive,
            "data-celledit-isdisabled": "false",
            "data-celledit-isclientcalculate": field.isClientCalculate,
            "data-celledit-iscalculateonclientchange":
              field.isCalculateOnClientChange,
            "data-celledit-ischeckunchanged": field.isCheckUnchanged,
            "data-celledit-isuniquecheckclientcalculate":
              field.isUniqueCheckClientCalculate,
            "data-celledit-istablerollup": field.isTableRollup,
            "data-celledit-isnoteditable": !field.isEditable,
            "data-celledit-iseditable": field.isEditable,
            "data-rolluptable": field.tablePublicId,
            "data-celledit-rollupfield": field.rollupFieldPublicId,
            "data-celledit-rollupformula": field.aggregateFunction,
            "data-celledit-calculatetriggerfieldpublicids":
              field.calculateTriggerFieldPublicIds,
            "data-celledit-isthousandseparator": field.isThousandSeparator,
            "data-celledit-formulaname": field.formulaName,
            "data-celledit-formulatext": field.formulaText,
            "data-celledit-predefinedpublicid": field.predefinedPublicId,
            "data-celledit-predefineddependencypublicid":
              field.predefinedDependencyPublicId,
            "data-celledit-viewfilterpublicid": field.viewFilterPublicId,
          }
        : {
            "data-publicid": field.publicId,
            "data-formulaname": field.formulaName,
          };
    },
    getEditColumnAttributes(field, filterField, editCellTemplateId) {
      return {
        "data-class": "grid-item",
        "data-fieldtype": field.fieldType,
        "data-inputtype": field.inputHtmlType,
        "data-maxlength": field.length,
        "data-islivelookup": field.isDataReadFromMainTable,
        "data-iscalculatedonlyoninsert": field.isCalculatedOnlyOnInsert,
        "data-decimalplaces": field.decimalPlaces,
        "data-inputmaskpattern": field.inputMaskPattern,
        "data-controllingpublicid": field.controllingPublicId,
        "data-lookupobjectid": field.lookupObjectPublicId,
        "data-lookupobjectname": field.lookupObjectName,
        "data-lookupobjecturl": field.lookupObjectKey,
        "data-lookupfieldids": field.lookupFieldPublicIds,
        "data-selecteditempublicids": field.defaultValuePublicIds,
        "data-organizationalunitname": field.organizationalUnitName,
        "data-organizationalunitfiltertype": field.organizationalUnitFilterType,
        "data-organizationalunitgrouppublicids":
          field.organizationalUnitGroupPublicIds,
        "data-organizationalunitdepth": field.organizationalUnitDepth,
        "data-organizationalunitincludeitself":
          field.organizationalUnitIncludeItself,
        "data-isencrypted": field.isEncrypted,
        "data-ismultiplevalue": field.isMultipleValue,
        "data-isradio": field.isRadio,
        "data-isrequired": field.isRequired || filterField.required,
        "data-isunique": field.isUnique,
        "data-isactive": field.isActive,
        "data-isdisabled": field.disabled || filterField.disabled,
        "data-isclientcalculate": field.isClientCalculate,
        "data-iscalculateonclientchange": field.isCalculateOnClientChange,
        "data-ischeckunchanged": field.isCheckUnchanged,
        "data-isuniquecheckclientcalculate": field.isUniqueCheckClientCalculate,
        "data-istablerollup": field.isTableRollup,
        "data-isnoteditable": !field.isEditable,
        "data-iseditable": field.isEditable,
        "data-rolluptable": field.tablePublicId,
        "data-rollupfield": field.rollupFieldPublicId,
        "data-rollupformula": field.aggregateFunction,
        "data-calculatetriggerfieldpublicids":
          field.calculateTriggerFieldPublicIds,
        "data-isthousandseparator": field.isThousandSeparator,
        "data-formulaname": field.formulaName,
        "data-formulatext": field.formulaText,
        "data-predefinedpublicid": field.predefinedPublicId,
        "data-predefineddependencypublicid": field.predefinedDependencyPublicId,
        "data-viewfilterpublicid": field.viewFilterPublicId,
        "data-publicid": field.publicId,
        "data-id": field.publicId,
        "data-editcelltemplateelementid": editCellTemplateId,
        spellcheck: this.$isTextSpellCheck,
        autocomplete: this.$isAutoComplete,
      };
    },
    getFieldTemplate(currentField, args, innerText) {
      var template = "";

      if (
        String.isNullOrWhiteSpace(innerText) &&
        currentField.fieldType != "Checkbox"
      ) {
        return this.$valueNotAvailableSeparator;
      }

      switch (currentField.fieldType) {
        case "Lookup":
          var fieldValueId =
            args.data[
              `${currentField.formulaName}${this.$fieldPublicIdFormulaExtension}`
            ];
          if (!String.isNullOrWhiteSpace(fieldValueId)) {
            if (currentField.isMultipleValue) {
              var fieldValueIds = fieldValueId.split(this.$systemSeparator),
                innerTexts = innerText.split(this.$systemSeparator);
              fieldValueIds.forEach((valueId, i) => {
                template += `<span class='badge border border-primary me-1 mb-1'><a target='_blank' href="#/set/detail/${currentField.lookupObjectKey}/${valueId}">${innerTexts[i]}</a></span>`;
              });
            } else {
              template = `<a target='_blank' href="#/set/detail/${currentField.lookupObjectKey}/${fieldValueId}">${innerText}</a>`;
            }
          }
          break;
        case "SelectList":
        case "OrganizationalUnit":
        case "Predefined":
          if (currentField.isMultipleValue) {
            var innerTextsSplited = innerText.split(this.$systemSeparator);
            innerTextsSplited.forEach((element) => {
              template += `<span class='badge text-dark border border-primary me-1 mb-1'>${element}</span>`;
            });
          }
          break;
        case "Checkbox":
          innerText = String.isNullOrWhiteSpace(innerText) ? false : innerText;
          template = Boolean.toBool(innerText.toString())
            ? "<i class='fa fa-check ms-2 text-success'></i>"
            : "<i class='fa fa-times ms-2 text-danger'></i>";
          break;
        case "Html":
          template = `<div class="dx-grid-html-field">${innerText}</div>`;
          break;
        case "Url":
          template = `<a target='_blank' href='${innerText}'><i class='fa fa-link text-primary'></i> ${innerText}</a>`;
          break;
        case "Email":
          template = `<a href='mailto:${innerText}'><i class='fa fa-at text-primary'></i> ${innerText}</a>`;
          break;
        case "Spatial":
        case "FormulaSpatial":
          template = `<i class="fas fa-map-marker-alt fa-2x text-primary cursor-pointer ignore-default-font-size view-map-with-swal" data-title="${currentField.name}" data-latlng="${innerText}"></i>`;
          break;
        case "Phone":
          template = `<a href='tel:${innerText}'><i class='fa fa-phone text-primary'></i> ${innerText}</a>`;
          break;
        case "Image":
        case "File":
          var recordIds =
            args.data[
              `${currentField.formulaName}${this.$fieldPublicIdFormulaExtension}`
            ];
          if (!String.isNullOrWhiteSpace(recordIds)) {
            var formFileListId = "ffl_" + String.newGuid();

            var childTemplate = "";
            recordIds = recordIds.split(this.$systemSeparator);
            innerText = innerText.split(this.$systemSeparator);
            recordIds.forEach((element, i) => {
              if (this.$root.isDocumentViewerFile(innerText[i])) {
                childTemplate += `<li><span><a>${innerText[i]}</a></span><span class="ms-2 cursor-pointer download-file" name="${innerText[i]}" id="${element}"><i class="fas fa-download text-primary"></i></span></li>`;
              } else {
                childTemplate += `<li><span class="ms-2 cursor-pointer download-file" name="${innerText[i]}" id="${element}"><i class="fas fa-download text-primary"></i> ${innerText[i]}</span></li>`;
              }
            });

            template = `<div class="form-file-list" id="${formFileListId}"><ul class="list-inline">${childTemplate}</ul></div>`;
          }
          break;
        case "Time":
          var valueFormat = this.$root.getTimeFormatByValue(
            innerText,
            this.dateTimeFormatInfo
          );

          template = this.$moment(innerText, valueFormat).format(
            this.dateTimeFormatInfo.time
          );
          break;
        default:
          break;
      }

      if (currentField.isEncrypted) {
        template = this.$root.decrypt(innerText);
      }

      return String.isNullOrWhiteSpace(template) ? innerText : template;
    },
    onCellPrepared(args) {
      if (args.rowType === "header") {
        String.SetAttributes(args.cellElement, {
          "data-columnformulaname": args.column.dataField,
        });
      } else if (args.rowType === "data" || args.rowType == "detailAdaptive") {
        var currentField = this.pageAllFields.find(
            (f) => f.formulaName === args.column.dataField
          ),
          recordPublicId = args.data[this.primaryKey],
          fieldBlock = $(args.cellElement);

        if (!String.isNullOrWhiteSpace(currentField)) {
          String.SetAttributes(
            args.cellElement,
            this.getColumnAttributes(currentField)
          );
        }

        if (args.row.isEditing) return;

        var displayOrderVisibleIndex = this.grid.getVisibleColumnIndex(
          this.serialNoKey
        );
        if (
          displayOrderVisibleIndex !== -1 &&
          args.columnIndex == displayOrderVisibleIndex
        ) {
          //serial no column
          var serialNumberColumn = args.cellElement,
            rowIndex = ++args.rowIndex,
            page = args.component.pageIndex(),
            pageSize = args.component.pageSize(),
            startIndex = page * pageSize,
            rowNumber = startIndex + rowIndex;

          serialNumberColumn.innerText = rowNumber;
        }

        if (String.isNullOrWhiteSpace(currentField)) return;

        fieldBlock.html(
          this.getFieldTemplate(
            currentField,
            args,
            args.data[currentField.formulaName]
          )
        );
      }
    },
    getColumnType(fieldType) {
      if (fieldType.includes("Number")) {
        // return "number";
        return "string";
      } else if (fieldType.includes("Date")) {
        // return "datetime";
        return "string";
      } else if (fieldType.includes("Checkbox")) {
        return "string";
      } else {
        return "string";
      }
    },
    getCellTemplateId(typeId, field) {
      var templateType = this.$root.dxGridTemplateTypes.find(
        (f) => f.id === typeId
      );
      if (!this.$root.fieldTypes.all.includes(field.fieldType) || !templateType)
        return null;

      return `${this.gridId}-${templateType.text}${field.fieldType}Template-${field.publicId}`;
    },
    isVisibleCustomActionButton(e) {
      if (e.row.isEditing) {
        return false;
      }
      return true;
    },
    getColumns() {
      var self = this,
        columns = [
          {
            text: this.$t(
              "BaseModelFields.SerialNo",
              {},
              { locale: this.$store.state.activeLang }
            ),
            formulaName: this.serialNoKey,
            fieldModel: null,
            type: "string",
            visible: !this.$isMobile(),
            disabled: true,
            width: 25,
            template: null,
            format: "",
            textAlign: "center",
            allowEditing: false,
            allowSorting: false,
            editCellTemplate: null,
            headerCellTemplate: null,
          },
          {
            text: this.$t(
              "BaseModelFields.RecordPublicId",
              {},
              { locale: this.$store.state.activeLang }
            ),
            formulaName: this.primaryKey,
            fieldModel: null,
            type: "string",
            visible: false,
            disabled: true,
            width: 0,
            template: "",
            format: null,
            textAlign: "",
            allowEditing: false,
            allowSorting: false,
            editCellTemplate: null,
            headerCellTemplate: null,
          },
        ];

      var isVisibleColumnReCalculateWidths =
          !this.$isMobile() && this.tableViewFilter.clientWidth <= 0,
        changedVisibleColumnWidth = 0;
      if (isVisibleColumnReCalculateWidths) {
        setTimeout(() => {
          changedVisibleColumnWidth = this.$root.calculateVisibleColumnWidth(
            this,
            true,
            this.tableViewFilter
          );
        });
      }

      this.tableViewFilter.visibleFields.map(function (filterField) {
        var field = filterField.fieldModel;
        field.disabled = filterField.disabled;

        var hiddenDependencies = self.tableViewFilter.hiddenFields.filter(
            (f) =>
              !String.isNullOrWhiteSpace(
                f.fieldModel.calculateTriggerFieldPublicIds
              ) &&
              f.fieldModel.calculateTriggerFieldPublicIds
                .split(self.$systemSeparator)
                .includes(field.publicId) &&
              f.fieldModel.fieldType == "Lookup"
          ),
          hiddenDependenyFieldItems = [];

        if (hiddenDependencies.length > 0) {
          hiddenDependencies.forEach((hiddenFilterField) => {
            var hiddenField = hiddenFilterField.fieldModel;
            var editCellTemplateId = self.getCellTemplateId(2, hiddenField),
              obj = {
                uId: String.newGuid(),
                id: editCellTemplateId + "-hidden",
                parent: self,
                field: hiddenField,
                filterField: hiddenFilterField,
                cell: {
                  htmlAttributes: self.getEditColumnAttributes(
                    hiddenField,
                    hiddenFilterField,
                    editCellTemplateId + "-hidden"
                  ),
                },
              };

            hiddenDependenyFieldItems.push(obj);

            self.pageAllFields.push(hiddenField);
            self.hiddenDependenyFieldItems.push(obj);

            self.tableViewFilter.hiddenFields =
              self.tableViewFilter.hiddenFields.filter(
                (f) => f.fieldModel.publicId != hiddenField.publicId
              );
          });
        }

        var editCellTemplateId = self.getCellTemplateId(2, field),
          isEditCellTemlateIdNull =
            String.isNullOrWhiteSpace(editCellTemplateId);
        if (!isEditCellTemlateIdNull) {
          self.editCellTemplates.push({
            id: editCellTemplateId,
            uId: String.newGuid(),
            parent: self,
            field: field,
            filterField: filterField,
            cell: {
              htmlAttributes: self.getEditColumnAttributes(
                field,
                filterField,
                editCellTemplateId
              ),
            },
            hiddenFieldDependencies: hiddenDependenyFieldItems,
          });
        }

        var headerCellTemplateId = self.getCellTemplateId(0, field);
        self.headerCellTemplates.push({
          id: headerCellTemplateId,
          field: field,
        });

        if (self.$store.state.isMultiLanguage) {
          var localizationValue =
            self.$store.getters.getLocalizationValuesByParameters({
              parentId: field.customObjectPublicId,
              itemFormulaName: field.formulaName,
              itemTypeId: 2,
            });
          if (localizationValue) {
            field.name = localizationValue.value;
          }
        }

        var columnWidth = isVisibleColumnReCalculateWidths
          ? changedVisibleColumnWidth
          : filterField.width;
        if (self.$isMobile() && filterField.width <= 0) {
          columnWidth = 200;
        }

        self.pageAllFields.push(field);
        columns.push({
          uId: String.newGuid(),
          text: field.name,
          formulaName: field.formulaName,
          fieldModel: field,
          fieldPublicId: field.publicId,
          sortOrder: undefined,
          sortIndex: undefined,
          visible: true,
          type: self.getColumnType(field.fieldType),
          textAlign: filterField.align,
          width: columnWidth,
          required: filterField.required,
          disabled: filterField.disabled,
          allowEditing: true,
          allowSorting: true,
          headerCellTemplate: headerCellTemplateId,
          editCellTemplate: editCellTemplateId,
        });
      });

      if (this.tableViewFilter.hiddenFields.length > 0) {
        this.tableViewFilter.hiddenFields.map(function (filterField) {
          var field = filterField.fieldModel;
          field.disabled = filterField.disabled;

          if (self.$store.state.isMultiLanguage) {
            var localizationValue =
              self.$store.getters.getLocalizationValuesByParameters({
                parentId: field.customObjectPublicId,
                itemFormulaName: field.formulaName,
                itemTypeId: 2,
              });
            if (localizationValue) {
              field.name = localizationValue.value;
            }
          }

          var headerCellTemplateId = self.getCellTemplateId(0, field);
          self.headerCellTemplates.push({
            id: headerCellTemplateId,
            field: field,
          });

          var editCellTemplateId = self.getCellTemplateId(2, field),
            isEditCellTemlateIdNull =
              String.isNullOrWhiteSpace(editCellTemplateId);
          if (!isEditCellTemlateIdNull) {
            self.editCellTemplates.push({
              id: editCellTemplateId,
              uId: String.newGuid(),
              parent: self,
              field: field,
              filterField: filterField,
              cell: {
                htmlAttributes: self.getEditColumnAttributes(
                  field,
                  filterField,
                  editCellTemplateId
                ),
              },
              hiddenFieldDependencies: [],
            });
          }

          self.pageAllFields.push(field);
          columns.push({
            uId: String.newGuid(),
            text: field.name,
            formulaName: field.formulaName,
            fieldModel: field,
            fieldPublicId: field.publicId,
            type: self.getColumnType(field.fieldType),
            visible: false,
            textAlign: filterField.align,
            required: false,
            disabled: filterField.disabled,
            width: 0,
            allowEditing: true,
            allowSorting: true,
            editCellTemplate: editCellTemplateId,
            headerCellTemplate: headerCellTemplateId,
          });
        });
      }

      //radio select list values array prepared
      var radioSelectListFields = this.pageAllFields.filter(
        (f) => f.fieldType == "SelectList" && f.isRadio
      );
      if (radioSelectListFields.length > 0) {
        radioSelectListFields.forEach((field) => {
          this.radioSelectListValues.push({
            fieldPublicId: field.publicId,
            items: [],
          });

          this.$root.getFieldItems(field.publicId).then((response) => {
            this.radioSelectListValues.find(
              (f) => f.fieldPublicId == response.fieldPublicId
            ).items = response.items;
          });
        });
      }

      return columns;
    },
    getButtons(type) {
      //type => 0 : devexpress action Buttons
      //type => 1 : custom action buttons

      var buttons = [];

      buttons.push({
        uId: String.newGuid(),
        isDxActionButton: true,
        name: "save",
        label: "",
        type: "button",
        cssClass: "btn-success dx-grid-record-save btn-icon",
        iconClass: "fas fa-save",
      });
      buttons.push({
        uId: String.newGuid(),
        isDxActionButton: true,
        name: "cancel",
        label: "",
        type: "button",
        cssClass: "btn-danger dx-grid-record-cancel btn-icon",
        iconClass: "fas fa-times-circle",
      });

      if (this.tableViewFilter.isEditButtonActive) {
        buttons.push({
          uId: String.newGuid(),
          isDxActionButton: false,
          name: "EditRecord",
          title: this.$t(
            "Components.ActionButtons.Edit",
            {},
            { locale: this.$store.state.activeLang }
          ),
          type: "button",
          cssClass: "btn-warning dx-grid-record-edit btn-icon",
          iconClass: "fas fa-edit",
        });
      }

      if (this.tableViewFilter.isDeleteButtonActive) {
        buttons.push({
          uId: String.newGuid(),
          isDxActionButton: false,
          name: "DeleteRecord",
          title: this.$t(
            "Components.ActionButtons.Delete",
            {},
            { locale: this.$store.state.activeLang }
          ),
          type: "button",
          cssClass: "btn-danger dx-grid-record-delete btn-icon",
          iconClass: "fas fa-trash-alt",
        });
      }

      return type == 0
        ? buttons.filter(function (f) {
            return f.isDxActionButton;
          })
        : buttons.filter(function (f) {
            return !f.isDxActionButton;
          });
    },
    getAggregates() {
      if (!this.table) return [];

      var items = [],
        self = this,
        aggregationFields = this.tableViewFilter.aggregationFields;
      if (aggregationFields.length > 0) {
        aggregationFields.forEach((field) => {
          items.push({
            type: field.aggregateFunction.toLowerCase(),
            column: field.formulaName,
            showInColumn: field.formulaName,
            alignment: "left", // or "center" | "right"
            displayFormat:
              this.$t(
                "BaseModelFields." + field.aggregateFunction,
                {},
                { locale: this.$store.state.activeLang }
              ) + " {0}",
          });
        });
      }

      return items;
    },
    getTable() {
      if (
        this.table == undefined ||
        this.tableViewFilter == undefined ||
        String.isNullOrWhiteSpace(this.tableViewFilter)
      ) {
        return;
      }

      this.devExpressActionButtons = this.getButtons(0);
      this.customActionButtons = this.getButtons(1);
      this.columns = this.getColumns();
      this.aggregates = this.getAggregates();
      this.isNotDrawAsMobile = this.tableViewFilter.isNotDrawAsMobile;
    },
    modelCreate(data, isNewRecord = true) {
      var model = {
          customObjectPublicId: this.table.lookupObjectPublicId,
          customObjectKey: this.table.lookupObjectUrlName,
          recordPublicId: isNewRecord
            ? this.newRowFieldDefaultValues.recordPublicId
            : data[this.primaryKey],
          values: [],
        },
        self = this;

      var obj = data;
      Object.keys(obj).forEach(function (key) {
        if (key == self.dxSystemPrimaryKey) return;

        var field = self.pageAllFields.find((f) => f.formulaName == key),
          value = String.isNullOrWhiteSpace(obj[key])
            ? ""
            : obj[key].toString();
        if (String.isNullOrWhiteSpace(field)) return;

        if (
          field.inputHtmlType == "file" &&
          !String.isNullOrWhiteSpace(value)
        ) {
          // var onlyDocumentIds = [];
          // value.split(self.$systemSeparator).forEach((element) => {
          //   onlyDocumentIds.push(element.split(",")[0]);
          // });

          value = obj[key + self.$fieldPublicIdFormulaExtension];
        } else if (
          self.$root.fieldTypes.select.includes(field.fieldType) &&
          obj[key + self.$fieldPublicIdFormulaExtension]
        ) {
          value = obj[key + self.$fieldPublicIdFormulaExtension];
        }

        model.values.push({
          key: key,
          value: value,
        });
      });

      //Hidden Dependency Item Value Operations
      if (this.hiddenDependenyFieldItems.length > 0) {
        this.hiddenDependenyFieldItems.forEach((hiddenField) => {
          var el = $("#" + hiddenField.id);
          if (el) {
            var elData = el.closest('.hidden[data-class="grid-item"]'),
              obj = { key: elData.data("formulaname"), value: el.val() },
              isModelValueItem = model.values.find((f) => f.key == obj.key);

            if (isModelValueItem) {
              isModelValueItem.value = obj.value;
            } else {
              model.values.push(obj);
            }
          }
        });
      }

      //NOTE:OAK: Kayıt ekleme sırasında modele işlenmeyen varsayılan değerleri model e işliyoruz.
      if (isNewRecord) {
        this.newRowFieldDefaultValues.items.forEach((defaultValue) => {
          var currentDefaultValue = model.values.find(
              (f) => f.key == defaultValue.key
            ),
            fieldValue = defaultValue.value;
          if (!currentDefaultValue && !String.isNullOrWhiteSpace(fieldValue)) {
            var currentField = this.pageAllFields.find(
              (f) => f.formulaName === defaultValue.key
            );
            if (!currentField) return;

            if (this.$root.fieldTypes.select.includes(currentField.fieldType)) {
              // var fieldValueIsMultiple =
              //     fieldValue.split(this.$systemSeparator).length > 2,
              var splittedValue = fieldValue.split(this.$selectSeparator);
              // if (fieldValueIsMultiple) {
              // fieldValue = splittedValue[0].split(this.$systemSeparator)[0];
              // } else {
              fieldValue = splittedValue[0];
              // }
            }

            model.values.push({
              key: defaultValue.key,
              value: fieldValue,
            });
          }
        });

        //TODO: #1549 fix - for default values
        model.values = model.values.filter(
          (f) => !String.isNullOrWhiteSpace(f.value)
        );
      }

      return model;
    },
    getRadioSelectListElementValue(field, elementId) {
      var valueObj = { id: "", text: "" },
        element = $(`[name="${elementId}"]`),
        checkedElements = $(`[name="${elementId}"]:checked`);

      if (
        !field ||
        !elementId ||
        !element ||
        (element && checkedElements.length == 0)
      )
        return valueObj;

      if (field.isMultipleValue) {
        var checkedPublicIds = [],
          checkedNames = [];

        checkedElements.each(function (i, checkedElement) {
          var value = $(checkedElement).val(),
            text = $(checkedElement).data("text");

          checkedPublicIds.push(value);
          checkedNames.push(text);
        });

        valueObj.id = checkedPublicIds.join(this.$systemSeparator);
        valueObj.text = checkedNames.join(this.$systemSeparator);
      } else {
        valueObj.id = checkedElements.val();
        valueObj.text = checkedElements.data("text");
      }

      return valueObj;
    },
    createGridItemObj(data) {
      var itemObj = {},
        self = this;

      itemObj[this.primaryKey] = data[this.primaryKey]
        ? data[this.primaryKey]
        : this.newRowFieldDefaultValues.recordPublicId;

      var obj = data;
      Object.keys(obj).forEach(function (key) {
        var field = self.pageAllFields.find((f) => f.formulaName == key),
          value = String.isNullOrWhiteSpace(obj[key])
            ? null
            : obj[key].toString();
        if (String.isNullOrWhiteSpace(field)) return;

        if (
          field.inputHtmlType == "file" &&
          !String.isNullOrWhiteSpace(value)
        ) {
          var onlyDocumentIds = [],
            onlyDocumentNames = [];
          value.split(self.$systemSeparator).forEach((element) => {
            onlyDocumentIds.push(element.split(",")[0]);
          });
          value.split(self.$systemSeparator).forEach((element) => {
            onlyDocumentNames.push(element.split(",")[1]);
          });

          itemObj[`${key}${self.$fieldPublicIdFormulaExtension}`] =
            onlyDocumentIds.join(self.$systemSeparator);
          value = onlyDocumentNames.join(self.$systemSeparator);
        } else if (self.$root.fieldTypes.select.includes(field.fieldType)) {
          var gridItem = $(
              `#${self.gridId} [data-class='grid-item'][data-publicid='${field.publicId}']`
            ),
            select = field.isRadio
              ? gridItem.find(
                  `[name="${gridItem.data("editcelltemplateelementid")}"]`
                )
              : gridItem.find(`#${gridItem.data("editcelltemplateelementid")}`),
            selectValue = select.val();
          if (!String.isNullOrWhiteSpace(selectValue)) {
            if (field.isRadio) {
              var valuesObj = self.getRadioSelectListElementValue(
                field,
                gridItem.data("editcelltemplateelementid")
              );

              value = valuesObj.text;

              itemObj[`${key}${self.$fieldPublicIdFormulaExtension}`] =
                valuesObj.id;
            } else {
              var el = select.select2("data"),
                id = el.map((u) => u.id).join(self.$systemSeparator);

              value = el.map((u) => u.text).join(self.$systemSeparator);

              itemObj[`${key}${self.$fieldPublicIdFormulaExtension}`] = id;
            }
          }
        }

        itemObj[key] = value;
      });

      return itemObj;
    },
    rowDblClick(e) {
      //
    },
    cellDblClick(args) {
      if (!args.row) return;

      var field = this.pageAllFields.find(
          (f) => f.formulaName === args.column.dataField
        ),
        recordPublicId = args.row.data[this.primaryKey];

      if (
        this.dxRowIsEditing ||
        String.isNullOrWhiteSpace(field) ||
        String.isNullOrWhiteSpace(recordPublicId)
      )
        return;

      if (!this.tableViewFilter.isCellEditingEnabled) {
        if (this.tableViewFilter.isEditInlineOn) {
          this.onCellDoubleClicked = true;
          // this.grid.editRow(args.rowIndex);
          this.rePaintChangesOnly = false;
          setTimeout(() => {
            this.rePaintChangesOnly = true;
            this.grid.editRow(args.rowIndex);
          }, 1);
        }
        return;
      }

      if (
        !field.isEditable ||
        field.disabled ||
        field.fieldType.includes("Formula") ||
        field.fieldType.includes("AutoId") ||
        field.fieldType == "ReadOnly" ||
        field.fieldType == "Rollup"
      ) {
        var message = this.$t(
          "FieldWarnings.NoEditableField",
          {},
          { locale: this.$store.state.activeLang }
        ).replaceAll("FIELD_TYPE", field.fieldType);
        if (field.disabled) {
          message = this.$t(
            "FieldWarnings.NoEditableFieldForViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          );
        } else if (!field.isEditable) {
          message = this.$t(
            "FieldWarnings.NoEditableFieldForUpdatePage",
            {},
            { locale: this.$store.state.activeLang }
          );
        }

        Swal.fire({
          html: `<span class='fw-bold fs-5'>${message}</span>`,
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText:
            "<i class='fa fa-times'></i>" +
            this.$t(
              "BaseModelFields.Close",
              {},
              { locale: this.$store.state.activeLang }
            ),
          customClass: {
            confirmButton: "btn fw-bold btn-success",
          },
        });
        return;
      }

      this.selectedRecordCellField = field;
      this.selectedRow = args;
      this.selectedRowData = this.dataSource.find(
        (f) => f[this.primaryKey] == recordPublicId
      );
      this.selectedRowIndex = args.rowIndex;
      this.selectedCellIndex = args.columnIndex;

      this.selectedRowRequiredFieldAndValues = this.getRowRequiredFieldValues(
        this.selectedRecordCellField.publicId,
        this.selectedRowData
      );

      $(`[data-bs-target="#${this.$refs.cellEditModal.modalId}"]`).trigger(
        "click"
      );
    },
    getRowRequiredFieldValues(excludeFieldPublicId, selectedRowData) {
      var requiredFields = this.pageAllFields.filter(function (f) {
        return (
          f.isRequired &&
          !f.fieldType.includes("Formula") &&
          !f.fieldType.includes("Rollup") &&
          !f.fieldType.includes("AutoId") &&
          f.publicId !== excludeFieldPublicId
        );
      });

      if (requiredFields.length == 0) return [];

      var fieldValues = [];
      requiredFields.forEach((field) => {
        var value = selectedRowData[field.formulaName];
        if (value) {
          fieldValues.push({ key: field.formulaName, value: value });
        }
      });

      return fieldValues;
    },
    sxScrollSetLeftValue(leftValue) {
      $(`#${this.gridId}-double-scroll-wrapper`).scrollLeft(leftValue);
    },
    onOptionChanged(e) {
      if (e.fullName.includes("sortOrder")) {
        this.$root.createEvent("SXGRID_ON_SORTING", {
          title: "Sorting",
          message: "Event triggered when changing the order of columns.",
          grid: {
            id: this.gridId,
            data: this.table,
            pagedItems: this.dataSource,
          },
        });
        if (!e.previousValue) {
          const sortedColumnCount = e.component
            .getVisibleColumns()
            .filter((c) => c.sortOrder).length;
          if (sortedColumnCount >= this.maxSortOrder + 1) {
            const colIndex = parseFloat(/\d+/.exec(e.fullName)[0]);
            e.component.columnOption(colIndex, "sortOrder", null);
          }
        }
      } else if (e.name == "editing" && !e.value) {
        this.dxRowIsEditing = false;
        this.isDxRowInlineEditing = false;
      } else if (e.name === "columns" && e.fullName.endsWith("width")) {
        this.configurationSxScroll();
      } else if (e.fullName == "searchPanel.text") {
        this.$root.createEvent("SXGRID_ON_SEARCH", {
          title: "Searching",
          message: "Event triggered when grid records on searching",
          grid: {
            id: this.gridId,
            data: this.table,
            pagedItems: this.dataSource,
          },
        });
      } else if (e.fullName == "editing.changes") {
        if (!String.isNullOrWhiteSpace(e.value)) {
          //force review validation messages
          this.onRowValidating({ newData: e.value[0].data });
        }

        var previousData =
            e.previousValue.length > 0 ? e.previousValue[0] : null,
          previousDataObj = {
            data: previousData ? previousData.data : null,
            type: previousData ? previousData.type : null,
            publicId: previousData ? previousData.key : null,
          },
          currentData = e.value.length > 0 ? e.value[0] : null,
          currentDataObj = {
            data: currentData ? currentData.data : null,
            type: currentData ? currentData.type : null,
            publicId: currentData ? currentData.key : null,
          };
        this.$root.createEvent("SXGRID_EDITING_CHANGES", {
          title: "Grid Editing Changes",
          message: "Event triggered when grid editing changes",
          grid: {
            id: this.gridId,
            data: this.table,
            pagedItems: this.dataSource,
          },
          editing: {
            values: {
              previous: previousDataObj,
              current: currentDataObj,
            },
            field: this.editingField,
          },
        });
      }
    },
  },
};
</script>
